<template>
  <div>
    <div v-if="users">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">New Activity Report</h4>
        </div>
      </div>

      <!-- Error Alert -->
      <danger-alert :errors="errors"></danger-alert>

      <div class="form-group text-left">
        <label>Activity Report Week Start Date:</label>
        <datepicker :minimumView="'day'" :maximumView="'month'" :initialView="'day'" v-model="timeCard.week_start_date" format="yyyy-MM-dd" :disabledDates="disabledDates" :highlighted="highlighted"></datepicker>
      </div>

      <div class="form-group text-left">
        <label>User(s):</label>
        <b-checkbox v-model="allUserSelected">Create for all Users</b-checkbox>
        <br>
        <p>OR</p>
        <v-select :clearable="false" :disabled="userDropdownDisabled" @change="selectUser" @search="searchTrainees" :options="users" label="full_name" placeholder="Select a User" style="background-color: white"></v-select>
      </div>

      <div class="footer float-right">
        <div class="btn-group">
          <router-link :to="{ name: 'TimeManagementIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
          <button v-on:click="createTimeCard" type="button" class="btn btn-success">Create Activity Report</button>
        </div>
      </div>

      <br>
    </div>

    <loading-overlay v-else></loading-overlay>

  </div>
</template>

<script>
import DangerAlert from '@/common/components/danger-alert.vue';
import { TimeCardService } from '@/common/services/time_card.service';
import Datepicker from 'vuejs-datepicker';
import { UserService } from '@/common/services/user.service';
import { FilterService } from '@/common/services/filter.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'TimeCardNew',
  components: {
    LoadingOverlay,
    DangerAlert,
    Datepicker,
  },
  data() {
    return {
      timeCard: {
        week_start_date: '',
        user_id: null,
      },
      formattedDate: '',
      errors: [],
      users: null,
      allUserSelected: false,
      userSelected: false,
      userDropdownDisabled: false,
      filters: FilterService.defaultData(),
      disabledDates: {
        customPredictor(date) {
          return (date.getDay() !== 0 || date <= new Date());
        },
      },
      highlighted: {
        customPredictor(date) {
          return (date.getDay() === 0 || date <= new Date());
        },
      },
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    createTimeCard() {
      if ((this.timeCard.user_id !== null || this.userSelected !== false) && (this.timeCard.week_start_date !== '')) {
        const tc = {
          week_start_date: this.formattedDate,
          status: 'In Progress',
          user_id: this.timeCard.user_id,
        };
        TimeCardService.create(tc)
          .then(({ data }) => {
            this.$router.push({ name: 'TimeManagementIndex' }, () => {
              this.$notify({
                title: 'Created Time Card',
                text: `Successfully created Time Card for week starting ${data.week_start_date}!`,
                type: 'success',
                width: 350,
              });
            });
          })
          .catch((err) => { this.errors = err.response.data.error; });
      } else {
        this.$notify({
          title: 'Fill in Form',
          text: 'Please ensure the form is filled out.',
          type: 'error',
          width: 350,
        });
      }
    },
    formatDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join('-');
    },
    searchTrainees(search, loading) {
      // This is called when the user enters anything in the v-select field, and calls the below search method
      loading(true);
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, self) => {
      const selfRef = self;

      // This method makes an api request on Tags looking for a tag name containing the search param
      let params;
      if (search.length > 1) {
        params = FilterService.build({ page: 1, per_page: 10 }, { first_name_or_last_name_cont: search });
      } else {
        params = FilterService.build({ page: 1, per_page: 10 }, {});
        params = FilterService.build({ page: 1, per_page: 10 }, {});
      }

      UserService.query(params)
        .then(({ data }) => {
          selfRef.searchResults = data;
          if (selfRef.searchResults.length === 0) {
            selfRef.disabledSelections = false;
          }
          loading(false);
        });
    }, 350),
    getUsers() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 30 }, this.filters);
      UserService.query(params)
        .then(({ data }) => {
          this.users = data;
        });
    },
    selectUser(val) {
      this.timeCard.user_id = val.id;
      this.userSelected = true;
    },
  },
  computed: {
    startDate() {
      return this.timeCard.week_start_date;
    },
  },
  watch: {
    startDate(val) {
      this.formattedDate = this.formatDate(val);
    },
    allUserSelected(val) {
      if (val) {
        this.timeCard.user_id = null;
        this.userDropdownDisabled = true;
        this.userSelected = true;
      } else {
        this.userDropdownDisabled = false;
        this.userSelected = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
